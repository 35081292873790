const LoginForm = ({ setPassword, setEmail, handleAction }) => {
    return (
        <div className="m-auto shadow-xl p-10 bg-white max-w-xl rounded">
            <h1 className="text-4xl font-black mb-4">Login</h1>
            <div className="mb-4">
                <input
                    className="input border border-gray-400 appearance-none rounded w-full px-3 py-3 focus focus:border-indigo-600 focus:outline-none active:outline-none active:border-indigo-600"
                    id="email"
                    type="text"
                    placeholder="Email"
                    autoFocus
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="mb-4">
                <input
                    className="input border border-gray-400 appearance-none rounded w-full px-3 py-3 focus focus:border-indigo-600 focus:outline-none active:outline-none active:border-indigo-600"
                    id="password"
                    type="password"
                    placeholder="Password"
                    autoFocus
                    onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button
                className="bg-indigo-600 hover:bg-blue-dark text-white font-bold py-3 px-6 rounded"
                onClick={handleAction}
            >
                Entrar
            </button>
        </div>
    );
};

export default LoginForm;
