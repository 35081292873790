import { useEffect, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import Header from './Header';
import Footer from './Footer';
import { auth } from '../common/firebase';
import { AuthDispatchContext } from '../common/context';

export default function Layout({ children }) {
    const setAuth = useContext(AuthDispatchContext);
    const [user, loading] = useAuthState(auth);

    useEffect(() => {
        if (user) {
            setAuth(user);
        }
    }, [user, loading, setAuth]);

    return (
        <div className="flex flex-col">
            <Header />
            <section>
                <section className="">{children}</section>
                <Footer />
            </section>
        </div>
    );
}
