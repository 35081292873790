import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './common/context';
import Layout from './layouts/Layout';
import CreatePage from './pages/Create';
import Home from './pages/Home';
import Login from './pages/Login';

const App = () => {
    return (
        <AuthProvider>
            <Router>
                <Layout>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/products" element={<Home />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/create" element={<CreatePage />} />
                        {/* <Route path="/cars/:key/edit" exact component={EditCarPage} /> */}
                    </Routes>
                </Layout>
            </Router>
        </AuthProvider>
    );
};

export default App;
