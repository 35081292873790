import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { database, storage } from '../common/firebase';

const logOut = () => {
    const authentication = getAuth();
    return signOut(authentication)
        .then(() => {
            console.log('Sign-out successful.');
            sessionStorage.removeItem('Auth Token');
            return true;
        })
        .catch((error) => {
            console.log(error);
            return false;
        });
};

const createProducts = async (data) => {
    try {
        const prodRef = await addDoc(collection(database, 'products'), data);

        console.log('Document written with ID: ', prodRef.id);
    } catch (e) {
        console.error('Error adding document: ', e);
    }
};

const uploadImages = async (file) => {
    // Create the file metadata
    const metadata = {
        contentType: 'image/jpeg',
    };

    // Upload file and metadata to the object 'images/mountains.jpg'
    const storageRef = ref(storage, 'images/' + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file, metadata);

    return new Promise((resolve, reject) => {
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
            'state_changed',
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                    default:
                }
            },
            (error) => {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case 'storage/unauthorized':
                        // User doesn't have permission to access the object
                        console.log('storage/unauthorized');
                        break;
                    case 'storage/canceled':
                        // User canceled the upload
                        console.log('storage/canceled');
                        break;

                    // ...

                    case 'storage/unknown':
                        // Unknown error occurred, inspect error.serverResponse
                        console.log('storage/unknown');
                        break;
                    default:
                }
                reject(error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    resolve(downloadURL);
                });
            }
        );
    });
};

const getProducts = async () => {
    const querySnapshot = await getDocs(collection(database, 'products'));
    let products = [];
    querySnapshot.forEach((product) => {
        products.push({
            productId: product.id,
            name: product.data().name,
            desc: product.data().desc,
            stock: product.data().stock,
            enable: product.data().enable,
            price: product.data().price,
            images: product.data().images,
        });
    });
    return products;
};

const signIn = async (email, password) => {
    const authentication = getAuth();

    return signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
            return response._tokenResponse.refreshToken;
        })
        .catch((error) => {
            console.log(error.code);
            if (error.code === 'auth/wrong-password') {
                alert('Please check the Password');
            }
            if (error.code === 'auth/user-not-found') {
                alert('Please check the Email');
            }
        });
};
const Services = { createProducts, uploadImages, getProducts, signIn, logOut };
export default Services;
