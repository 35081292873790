import CreateForm from '../components/CreateForm';

const CreatePage = () => {
    return (
        <div className="w-full bg-blue-200">
            <CreateForm />
        </div>
    );
};

export default CreatePage;
