import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Services from '../services';

const CreateForm = () => {
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();
    const navigate = useNavigate();

    const handleImages = async () => {
        const imagesRef = getValues('images');
        const images = imagesRef.item(0);
        return await Services.uploadImages(images);
    };

    const onSubmit = async (data) => {
        const imagePath = await handleImages();
        const newData = { ...data, images: imagePath };

        await Services.createProducts(newData);
        navigate('/');
    };

    return (
        <form className="w-3/5 m-auto p-4" onSubmit={handleSubmit(onSubmit)}>
            {/* register your input into the hook by invoking the "register" function */}
            <input
                className="w-full rounded p-2 mt-4"
                placeholder="Nombre"
                {...register('name', { required: true })}
            />
            {errors.name && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )}

            <input
                className="w-full rounded p-2 mt-4"
                placeholder="Descripcion"
                {...register('desc', { required: false })}
            />
            {errors.desc && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )}
            <input
                className="w-full rounded p-2 mt-4"
                placeholder="Precio"
                type="number"
                {...register('price', { required: true })}
            />
            {errors.price && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )}
            <input
                className="w-full rounded p-2 mt-4"
                placeholder="Stock"
                type="number"
                {...register('stock', { required: true })}
            />
            {errors.stock && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )}
            <input
                className="w-full rounded p-2 mt-4"
                type="file"
                {...register('images')}
            />
            {/* {errors.images && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )} */}
            {/* <div className="flex w-full mt-4 items-center">
                <input
                    className="mr-2"
                    type="checkbox"
                    name="enable"
                    value={true}
                    {...register('enable', { required: true })}
                />
                <label htmlFor="enable">Activo</label>
            </div>

            {errors.enable && (
                <p className="text-red-600">Este campo es obligatorio</p>
            )} */}

            <div className="w-full flex justify-center mt-4">
                <input
                    className="w-1/2 bg-pink-200 hover:bg-pink-100 p-2 cursor-pointer"
                    type="submit"
                    value="CREAR"
                />
            </div>
        </form>
    );
};

export default CreateForm;
