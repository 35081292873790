import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../common/context';

import Card from '../components/Card';
import Services from '../services';

const Home = () => {
    const [products, setProducts] = useState([]);
    const auth = useContext(AuthContext);

    useEffect(() => {
        const getProducts = async () => {
            const res = await Services.getProducts();
            console.log(res);
            setProducts(res);
        };
        getProducts();
    }, []);

    return (
        <div className="container mx-auto">
            <section>
                <div className="w-full flex max-w-screen-md m-auto mb-10 mt-4 font-medium">
                    <span className="text-3xl text-indigo-500 inline ">“</span>
                    <p className="text-xl text-gray-600 text-center md:-mb-2 mt-2 inline-block px-2">
                        Aquí encontrarás una lista de productos y artesanias que
                        tengo para vender. Cualquier consulta lo pueden hacer
                        por WhatsApp{' '}
                        <span role="img" aria-label="whatsapp">
                            📲
                        </span>
                    </p>
                    <span className="text-3xl text-indigo-500 inline ">”</span>
                </div>
            </section>
            <section className="flex justify-center">
                {auth && (
                    <Link to={'/create'}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-10 w-10 text-green-600"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                        </svg>
                    </Link>
                )}
            </section>
            <div className="my-5 flex flex-wrap -mx-2">
                {products.map((p) => (
                    <div
                        key={p.productId}
                        className="w-full sm:w-1/2 lg:w-1/3 px-2 my-2"
                    >
                        <Card {...p} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Home;
