import React, { createContext, useState } from 'react';

// Create two context:
// AuthContext: to query the context state
// AuthDispatchContext: to mutate the context state
const AuthContext = createContext(undefined);
const AuthDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function AuthProvider({ children }) {
    const [auth, setAuth] = useState(undefined);

    return (
        <AuthContext.Provider value={auth}>
            <AuthDispatchContext.Provider value={setAuth}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthContext.Provider>
    );
}

export { AuthProvider, AuthContext, AuthDispatchContext };
