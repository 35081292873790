const Footer = () => {
    return (
        <footer className="font-bold bg-gray-200 text-center lg:text-left">
            <div className="text-gray-700 text-center p-4">
                © 2022 - Created with{' '}
                <span role="img" aria-label="whatsapp">
                    ❤️
                </span>{' '}
                by{' '}
                <a
                    className="text-gray-800"
                    target={'_blank'}
                    rel="noreferrer"
                    href="https://www.instagram.com/ann0nip"
                >
                    Ann0nIp
                </a>
            </div>
        </footer>
    );
};

export default Footer;
