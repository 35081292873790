import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext, AuthDispatchContext } from '../common/context';
import LoginForm from '../components/LoginForm';
import Services from '../services';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const auth = useContext(AuthContext);
    const setAuth = useContext(AuthDispatchContext);

    useEffect(() => {
        if (auth) {
            navigate('/');
        }
    }, [auth, navigate]);

    const handleLogin = async () => {
        const authToken = await Services.signIn(email, password);
        if (authToken) {
            sessionStorage.setItem('Auth Token', authToken);
            setAuth({ isLogged: true });
        }
    };
    return (
        <LoginForm
            setEmail={setEmail}
            setPassword={setPassword}
            handleAction={() => handleLogin()}
        />
    );
};
export default Login;
