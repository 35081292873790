import React from 'react';
import ModalImage from 'react-modal-image';

const phoneNumber = process.env.REACT_APP_TEL;
const Card = ({ images, productId, name, price, stock, desc, enable }) => {
    return (
        <div className="card h-full">
            <div className="relative bg-white shadow-md rounded-3xl p-2 mx-1 my-3">
                <div className="overflow-x-hidden rounded-2xl relative">
                    <ModalImage
                        className="h-40 rounded-2xl w-full object-contain"
                        small={images}
                        large={images}
                        alt={desc}
                    />
                    ;
                    {/* <p class="absolute right-2 top-2 bg-white rounded-full p-2 cursor-pointer group">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 group-hover:opacity-50 opacity-70"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="black"
                        >
                            <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                                d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                            />
                        </svg>
                    </p> */}
                </div>
                <div className="mt-4 pl-2 mb-2 flex justify-between ">
                    <div>
                        <p className="text-lg font-semibold text-gray-900 mb-0">
                            <span>🛍</span> {name}
                        </p>
                        <p className="text-md text-gray-900 mb-2">
                            <span>📝</span> {desc}
                        </p>
                        <p className="text-md text-gray-800 mt-0 font-semibold">
                            {'$ ' + price}
                        </p>
                    </div>
                    <div className="flex flex-col items-center justify-end mb-1 mr-4 group cursor-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                            />
                        </svg>
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://wa.me/+${phoneNumber}?text=Hola, estoy interesado/a en el producto ${name}`}
                        >
                            <span className="text-xs">WhatsApp</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
