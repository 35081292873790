import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../common/context';
import Services from '../services';

export default function Navbar() {
    const auth = useContext(AuthContext);

    return (
        <>
            <nav className="relative flex flex-wrap items-center justify-between py-2 bg-pink-500 ">
                <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between ">
                        <a
                            className="text-slate-100 font-title text-3xl font-bold leading-relaxed inline-block  whitespace-nowrap text-amber-200"
                            href="/"
                        >
                            Tienda mil destellos{' '}
                            <span role="img" aria-label="destellos">
                                ✨
                            </span>
                        </a>
                        {auth && (
                            <button
                                className="items-center text-white cursor-pointer text-xl  px-3 py-2 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                                type="button"
                                onClick={async () => {
                                    await Services.logOut();
                                    window.location.reload(false);
                                }}
                            >
                                Salir
                            </button>
                        )}
                        {!auth && (
                            <Link
                                className="items-center text-white cursor-pointer text-xl  px-3 py-2 border border-solid border-transparent rounded bg-transparent block outline-none focus:outline-none"
                                to={'/login'}
                            >
                                Login
                            </Link>
                        )}
                    </div>
                </div>
            </nav>
        </>
    );
}
